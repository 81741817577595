import React, { PureComponent } from 'react';
import IsolatedInput from 'core/components/IsolatedInput';
import TableClipboardHelper from 'dash-table/utils/TableClipboardHelper';
import FilterOptions from 'dash-table/components/Filter/FilterOptions';
export default class ColumnFilter extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value
        };
    }
    submit = (value) => {
        const { setFilter } = this.props;
        setFilter({
            target: { value }
        });
    };
    render() {
        const { className, columnId, filterOptions, isValid, style, toggleFilterOptions, value } = this.props;
        return (React.createElement("th", { className: className + (isValid ? '' : ' invalid'), "data-dash-column": columnId, style: style },
            React.createElement(IsolatedInput, { onCopy: (e) => {
                    e.stopPropagation();
                    TableClipboardHelper.clearClipboard();
                }, onPaste: (e) => {
                    e.stopPropagation();
                }, value: value, placeholder: 'filter data...', stopPropagation: true, submit: this.submit }),
            React.createElement(FilterOptions, { filterOptions: filterOptions, toggleFilterOptions: toggleFilterOptions })));
    }
}
