import * as R from 'ramda';
import { memoizeOneFactory } from 'core/memoizer';
import { FilterLogicalOperator } from 'dash-table/components/Table/props';
import { SingleColumnSyntaxTree, MultiColumnsSyntaxTree, getMultiColumnQueryString, getSingleColumnMap } from 'dash-table/syntax-tree';
const cloneIf = (current, base) => current === base ? new Map(base) : current;
export default memoizeOneFactory((map, operator, query, columns) => {
    const multiQuery = new MultiColumnsSyntaxTree(query, operator);
    const reversedMap = getSingleColumnMap(multiQuery, columns);
    /*
     * Couldn't process the query, just use the previous value.
     */
    if (!reversedMap) {
        return map;
    }
    /* Mapping multi-column to single column queries will expand
     * compressed forms. If the new ast query is equal to the
     * old one, keep the old one instead.
     *
     * If the value was changed by the user, the current ast will
     * have been modified already and the UI experience will also
     * be consistent in that case.
     */
    let newMap = map;
    const keys = R.uniq(R.concat(Array.from(map.keys()), Array.from(reversedMap.keys())));
    R.forEach(key => {
        const ast = map.get(key);
        const reversedAst = reversedMap.get(key);
        if (R.isNil(reversedAst)) {
            newMap = cloneIf(newMap, map);
            newMap.delete(key);
        }
        else if (R.isNil(ast) ||
            reversedAst.toQueryString() !== ast.toQueryString()) {
            newMap = cloneIf(newMap, map);
            newMap.set(key, reversedAst);
        }
    }, keys);
    return newMap;
});
function updateMap(map, column, value) {
    const id = column.id.toString();
    const newMap = new Map(map);
    if (value && value.length) {
        newMap.set(id, new SingleColumnSyntaxTree(value, column));
    }
    else {
        newMap.delete(id);
    }
    return newMap;
}
function updateState(map, operator, setFilter) {
    const asts = Array.from(map.values());
    const globalFilter = getMultiColumnQueryString(asts, operator);
    const rawGlobalFilter = R.map(ast => ast.query || '', R.filter(ast => Boolean(ast), asts)).join(operator === FilterLogicalOperator.And ? ' && ' : ' || ');
    setFilter(globalFilter, rawGlobalFilter, map);
}
export const updateColumnFilter = (map, column, operator, value, setFilter) => {
    map = updateMap(map, column, value);
    updateState(map, operator, setFilter);
};
export const clearColumnsFilter = (map, columns, operator, setFilter) => {
    R.forEach(column => {
        map = updateMap(map, column, '');
    }, columns);
    updateState(map, operator, setFilter);
};
