import * as R from 'ramda';
import SheetClip from 'sheetclip';
import Clipboard from 'core/Clipboard';
import Logger from 'core/Logger';
import { createHeadings } from 'dash-table/components/Export/utils';
import applyClipboardToData from './applyClipboardToData';
import getHeaderRows from 'dash-table/derived/header/headerRows';
export default class TableClipboardHelper {
    static lastLocalCopy = [[]];
    static localCopyWithoutHeaders = [[]];
    static toClipboard(e, selectedCells, columns, visibleColumns, data, includeHeaders) {
        const selectedRows = R.uniq(R.pluck('row', selectedCells).sort((a, b) => a - b));
        const selectedCols = R.uniq(R.pluck('column', selectedCells).sort((a, b) => a - b));
        const df = R.slice(R.head(selectedRows), R.last(selectedRows) + 1, data).map(row => R.props(selectedCols, R.props(R.pluck('id', visibleColumns), row)));
        let value = SheetClip.prototype.stringify(df);
        TableClipboardHelper.lastLocalCopy = df;
        if (includeHeaders) {
            const transposedHeaders = createHeadings(R.pluck('name', visibleColumns), getHeaderRows(columns));
            const headers = R.map((row) => R.map((index) => row[index], selectedCols), transposedHeaders);
            const dfHeaders = headers.concat(df);
            value = SheetClip.prototype.stringify(dfHeaders);
            TableClipboardHelper.lastLocalCopy = dfHeaders;
            TableClipboardHelper.localCopyWithoutHeaders = df;
        }
        Logger.trace('TableClipboard -- set clipboard data: ', value);
        Clipboard.set(e, value);
    }
    static clearClipboard() {
        TableClipboardHelper.lastLocalCopy = [];
        TableClipboardHelper.localCopyWithoutHeaders = [];
    }
    static fromClipboard(ev, activeCell, derived_viewport_indices, columns, visibleColumns, data, overflowColumns = true, overflowRows = true, includeHeaders) {
        const text = Clipboard.get(ev);
        Logger.trace('TableClipboard -- get clipboard data: ', text);
        if (!text) {
            return;
        }
        const localDf = SheetClip.prototype.stringify(TableClipboardHelper.lastLocalCopy);
        const localCopy = includeHeaders
            ? TableClipboardHelper.localCopyWithoutHeaders
            : TableClipboardHelper.lastLocalCopy;
        const values = localDf === text ? localCopy : SheetClip.prototype.parse(text);
        return applyClipboardToData(values, activeCell, derived_viewport_indices, columns, visibleColumns, data, overflowColumns, overflowRows);
    }
}
